import { Fragment } from 'react';
import classes from './LeftContact.module.css';
const LeftContact = () => {
  return (
    <Fragment>
      <div className={classes.ctnInfo}>
        <p><span>Telefon</span>0740801010</p>
        <p><span>Mail</span>manager@ismanager.ro</p>
      </div>
    </Fragment>
  );
};

export default LeftContact;
