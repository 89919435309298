import { Fragment } from 'react';
import clubImg from '../../assets/club.png';
import antrenorImg from '../../assets/antrenori.png';
import parintiCopiiImg from '../../assets/parinti-copii.png';
import classes from './TipuriConturi.module.css';
const TipuriConturi = () => {
  return (
    <Fragment>
      <div className={classes.ctnMare}>
        <div className="centru">
          <div className={classes.ctnConturi}>
            <div className={`${classes.ctnCont} ${classes.contLeft}`}>
              <div className={classes.img}>
                <img src={clubImg} alt='cluburile sportive' />
              </div>
              <div className={classes.info}>
                <div className={classes.titlu}>Oficialii Cluburilor si Federatiilor Nationale</div>
                <div className={classes.text}>
                  <p>Pastrati evidenta completa a personalului si a membrilor de la distanta prin aplicatia isManager. Aveti o interactiune individuala cu toti membrii pentru a construi o comunitate mai puternica in jurul clubului dumneavoastra.</p>
                  <p>Programati evenimentele/clasele in functie de disponibilitatea antrenorilor dumneavoastra pentru a gestiona afacerea fara probleme.</p>
                </div>
              </div>
            </div>
            <div className={`${classes.ctnCont} ${classes.contRight}`}>
              <div className={classes.img}>
                <img src={antrenorImg} alt='antrenorii' />
              </div>
              <div className={classes.info}>
                <div className={classes.titlu}>Antrenorii</div>
                <div className={classes.text}>
                  <p>isManager face viata antrenorilor mult mai usoara, ajutand la comunicarea mai eficienta cu sportivii si parintii acestora, vizualizeaza istoricul rezultatelor pentru fiecare sportiv si gestioneaza online competitiile.</p>
                  <p>Functiile esentiale de management disponibile antrenorilor sunt accesarea rapoartelor, fisa sportivi si vize sportive si medicale.</p>
                </div>
              </div>
            </div>
            <div className={`${classes.ctnCont} ${classes.contLeft}`}>
              <div className={classes.img}>
                <img src={parintiCopiiImg} alt='parintii si sportivii' />
              </div>
              <div className={classes.info}>
                <div className={classes.titlu}>Parintii si sportivii</div>
                <div className={classes.text}>
                  <p>Parintii si sportivii interactioneaza mai usor cu echipa sportiva si antrenorii clubului, primesc instiintari prin notificari push si mail-uri legate de urmatoarele antrenamente si competitii.</p>
                  <p>Acceseaza istoricul platilor si rapoarte disponibile cu rezultatele pe perioade definite.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TipuriConturi;
