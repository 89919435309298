import React, {Fragment} from 'react';
import { Route, Routes, Navigate, Link } from 'react-router-dom';
import FirstPage from './components/FirstPage/FirstPage';
import Contact from './components/Contact/Contact';
import ConfidentialitateDate from './components/PaginiStatice/ConfidentialitateDate';
import PrivacyPolicy from './components/PaginiStatice/PrivacyPolicy';

const App = () => {
  return (
    <Fragment>
      <Routes>
        <Route path='/' element={<FirstPage />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/confidentialitate-date' element={<ConfidentialitateDate />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='*' element={<FirstPage />} />
      </Routes>
    </Fragment>
  );
}

export default App;
