import { Fragment } from 'react';
import Menu from '../Layout/Menu';
import Footer from '../Layout/Footer';
import LeftContact from './LeftContact';
import FormContact from './FormContact';
import classes from './Contact.module.css';
const Contact = () => {
  return (
    <Fragment>
      <Menu />
        <div className={classes.ctnMare}>
          <div className="centru">
          <div className={classes.titluMare}>Contact</div>
          <div className={classes.ctnGrid}>
            <LeftContact />
            <FormContact />
          </div>
          </div>
        </div>
      <Footer />
    </Fragment>
  );
};

export default Contact;
