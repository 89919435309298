import { Fragment } from 'react';
import appStore from '../../assets/appStore.png';
import classes from './ButonApple.module.css';
const ButonApple = () => {
  return (
    <Fragment>
      <div className={classes.apple}>
        <img src={appStore} alt='' />
        <div className={classes.txtApple}>
          <p>Descarcati de pe</p>
          App Store
        </div>
      </div>
    </Fragment>
  );
};
export default ButonApple;
