import { Fragment } from 'react';
import Menu from '../Layout/Menu';
import Banner from './Banner';
import Beneficii2 from './Beneficii2';
import TipuriConturi from './TipuriConturi';
import Descarca from './Descarca';
import Footer from '../Layout/Footer';
const FirstPage = () => {
  return (
    <Fragment>
      <Menu />
      <Banner />
      <Beneficii2 />
      <TipuriConturi />
      <Descarca />
      <Footer />
    </Fragment>
  );
};

export default FirstPage;
