import { Fragment } from 'react';
import classes from './Beneficii2.module.css';
import telImg from '../../assets/telefon.png';
const Beneficii2 = () => {
  return (
    <Fragment>
      <div className={classes.ctnMare}>
        <div className="centru">
          <div className={classes.titluMare}>Beneficiile clubului tau sportiv</div>
          <div className={classes.txtBeneficii}>
            <p>Gestioneaza comunicarea intre club si sportivi sau parinti folosind o aplicatie atat web-based, cat si pentru mobil.</p>
            <p>Aplicatia isManager va permite sa administrati toate datele membrilor si antrenorilor dumneavoastra intr-un singur loc, inclusiv informatiile despre cont, istoricul platilor, prezenta, antrenamentele, competitiile si multe altele.</p>
          </div>
          <div className={classes.gridCtn}>
            <div className={`${classes.beneficiu} ${classes.verde} ${classes.txtAlb}`}>
              <div className={classes.titlu}>Administrare pe niveluri</div>
              <div className={classes.info}>
                <p>Aplicatia permite gestionarea informatiilor sub forma unui sistem structurat pe niveluri</p>
              </div>
            </div>
            <div className={classes.beneficiu}>
              <div className={classes.titlu}>Statistici financiare</div>
              <div className={classes.info}>
                <p>Accesarea statisticilor economico-financiare direct din aplicatie</p>
              </div>
            </div>
            <div className={classes.beneficiu}>
              <div className={classes.titlu}>Calendar organizatoric</div>
              <div className={classes.info}>
                <p>Generare program de antrenament si competitional, cu notificari de prezenta si feedback catre sportivi si parinti</p>
              </div>
            </div>
            <div className={`${classes.beneficiu} ${classes.portocaliu}  ${classes.txtAlb}`}>
              <div className={classes.titlu}>Fișa sportivului</div>
              <div className={classes.info}>
                <p>Parintii sunt informati cu privire la randamentul sportivului in timpul antrenamentelor si au acces la calificativele acestuia</p>
              </div>
            </div>
            <div className={classes.img}>
              <img src={telImg} alt='' />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Beneficii2;
