import { Fragment, useRef } from 'react';
import classes from './FormContact.module.css';
import Input from '../UI/Input';
import Textarea from '../UI/Textarea';
const FormContact = () => {
  const emailInputRef = useRef();
  const nameInputRef = useRef();
  const telInputRef = useRef();
  const messageInputRef = useRef();
  return (
    <form>
    <Input
        ref={nameInputRef}
        id="nume"
        label="Nume"
        type="text"
        isValid=""
        value=""
        onChange=""
        onBlur=""
      />
    <Input
        ref={telInputRef}
        id="tel"
        label="Telefon"
        type="text"
        isValid=""
        value=""
        onChange=""
        onBlur=""
      />
    <Input
        ref={emailInputRef}
        id="email"
        label="E-Mail"
        type="email"
        isValid=""
        value=""
        onChange=""
        onBlur=""
      />
    <Textarea
        ref={messageInputRef}
        id="mesaj"
        label="Mesaj"
        isValid=""
        value=""
        onChange=""
        onBlur=""
      />
      <button className={classes.btnSubmit}>Contacteaza-ne</button>
    </form>
  );
};

export default FormContact;
