import { Fragment } from 'react';
import telImg from '../../assets/telefoane.png';
import classes from './Descarca.module.css';
import ButonGoogle from '../Layout/ButonGoogle';
import ButonApple from '../Layout/ButonApple';
const Descarca = () => {
  return (
    <Fragment>
      <div className={classes.ctnMare}>
        <div className="centru">
          <div className={classes.gridCtn}>
            <div className={classes.infoLeft}>
              <img src={telImg} alt='' />
            </div>
            <div className={classes.infoRight}>
              <div className={classes.titlu}>Descarca aplicatia IsManager</div>
              <div className={classes.text}>
                <p>Testeaza gratuit aplicatia si du clubul tau sportiv la un alt nivel.</p>
              </div>
              <div className={classes.butoaneDownload}>
                <ButonGoogle />
                <ButonApple />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Descarca;
