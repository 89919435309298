import React, { useRef } from 'react';

import classes from './Textarea.module.css';

const Textarea = React.forwardRef((props, ref) => {
  const inputRef = useRef();

  const activate = () => {
    inputRef.current.focus();
  };

  /*useImperativeHandle(ref, () => {
    return {
      focus: activate,
    };
  });*/

  return (
    <div
      className={`${classes.control} ${
        props.isValid === false ? classes.invalid : ''
      }`}
    >
      <label htmlFor={props.id}>{props.label}</label>
      <textarea
        ref={inputRef}
        id={props.id}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
      />
    </div>
  );
});

export default Textarea;
