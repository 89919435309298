import { Fragment } from 'react';
import googlePlay from '../../assets/googlePlay.png';
import classes from './ButonGoogle.module.css';
const ButonGoogle = () => {
  return (
    <Fragment>
      <div className={classes.google}>
        <img src={googlePlay} alt='' />
        <div className={classes.txtGoogle}>
          <p>Acum pe</p>
          Google Play
        </div>
      </div>
    </Fragment>
  );
};
export default ButonGoogle;
