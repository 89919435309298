import { Fragment } from 'react';
import telImg from '../../assets/telefonSus.png';
import classes from './Banner.module.css';
import ButonGoogle from '../Layout/ButonGoogle';
import ButonApple from '../Layout/ButonApple';
const Banner = () => {
  return (
    <Fragment>
      <div className={classes.bannerSus}>
        <div className="centru">
          <div className={classes.gridCtn}>
            <div className={classes.infoLeft}>
              <div className={classes.titluMare}>
                <span><span>is</span>Manager</span> - Aplicatie pentru managementul cluburilor sportive
              </div>
              <div className={classes.textLeft}>
                <p>Gestioneaza clubul tau cu profesionalism si incredere.</p>
                <p>Toate informatiile la un click distanta.</p>
              </div>
              <div className={classes.butoaneDownload}>
                <ButonGoogle />
                <ButonApple />
              </div>
            </div>
            <div className={classes.imgTel}>
              <img src={telImg} alt='' />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Banner;
