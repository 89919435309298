import { Fragment } from 'react';
import Menu from '../Layout/Menu';
import Footer from '../Layout/Footer';
import classes from './PaginiStatice.module.css';
const PrivacyPolicy = () => {
    return (
        <Fragment>
            <Menu />
            <div className={classes.ctnMare}>
                <div className="centru">
                    <div className={classes.ctnStatic}>
                        <h1>Privacy Policy</h1>
                        <p>Lider4IT Junior built the isManager app as a free app. This service is provided by Lider4IT Junior at no cost and is intended for use as is.</p>
                        <p>This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information, which you agree to by using our service.</p>

                        <h3>Information Collection and Use</h3>

                        <p>While using our service, we may require you to provide us with certain personally identifiable information. The information that we request will be used to assure the functionality of our service and will never be shared with any third party entity.</p>

                        <h3>Security</h3>

                        <p>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it.</p>
                        <p>But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>

                        <h3>Changes to This Privacy Policy</h3>

                        <p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page.</p>

                        <p>This policy is effective as of 2022-12-09</p>

                        <h3>Contact Us</h3>

                        <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at contact@liderit.ro.</p>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
};

export default PrivacyPolicy;
