import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/logo.png';
import classes from './Menu.module.css';
import LinksMenu from './LinksMenu';
const Menu = () => {
  return (
    <Fragment>
      <div className={classes.menu}>
        <div className="centru">
          <div className={classes.gridMenu}>
            <div className={classes.logo}>
              <NavLink to='/'><img src={logo} alt='' /></NavLink>
            </div>
            <div className={classes.linksMenu}>
              <LinksMenu />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Menu;
