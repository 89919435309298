import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import classes from './Footer.module.css';
const Footer = () => {
  return (
    <Fragment>
      <div className={classes.ctnMare}>
        <div className="centru">
          <div className={classes.ctnGrid}>
            <div className={classes.copyright}>
              &copy; - Copyright <a>Lider4IT</a> | All rights reserved
            </div>
            <div className={classes.linkFooter}>
              <NavLink to='/confidentialitate-date'>Confidentialitate Date</NavLink>
              <a>Noutati</a>
              <a>Aplicatie web</a>
              <NavLink to='/contact'>Contact</NavLink>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Footer;
