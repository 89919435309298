import { Fragment } from 'react';
import Menu from '../Layout/Menu';
import Footer from '../Layout/Footer';
import classes from './PaginiStatice.module.css';
const ConfidentialitateDate = () => {
    return (
        <Fragment>
            <Menu />
            <div className={classes.ctnMare}>
                <div className="centru">
                    <div className={classes.ctnStatic}>
                        <h1>Politica de confidentialitate</h1>
                        <p>isManager, facuta de Lider4IT Junior, este o aplicatie gratuita. Serviciul acesteia este conceput pentru a fi utilizat fara niciun cost.</p>
                        <p>Aceasta pagina are ca scop informarea utilizatorilor privind politica noastra si prin folosirea serviciului nostru va dati acordul cu aceasta.</p>

                        <h3>Colectarea si utilizarea informatiilor cu caracter personal</h3>

                        <p>Pe parcursul folosirii serviciului nostru, puteti fi nevoiti sa oferiti date cu caracter personal. Noi garantam ca aceste date se vor folosi doar pentru buna functionalitate a aplicatiei si indeplinirea scopului acesteia, si nu vor fi transmise niciodata
                            unei entitati tertiere.</p>

                        <h3>Securitate</h3>

                        <p>Apreciem increderea de a oferi date cu caracter personal folosind serviciul nostru, de aceea facem tot posibilul pentru a ne asigura ca acestea sunt prelucrate si/sau transmise folosind cele mai sigure metode.</p>
                        <p>Va rugam sa tineti cont ca nicio metoda de a transmite date intr-un mediu online sau de a le stoca pe un dispozitiv electronic nu este niciodata 100% sigura, de aceea nu putem garanta securitate absoluta.</p>

                        <h3>Schimbari aduse politicii de confidentialitate.</h3>

                        <p>Politica noastra de confidentialitate poate fi modificata oricand. Va sfatuim sa verificati aceasta pagina periodic.</p>

                        <h3>Contactati-ne</h3>

                        <p>Daca aveti orice intrebare sau sugestie legata de politica noastra de confidentialitate, nu ezitati sa ne contactati la contact@liderit.ro</p>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
};

export default ConfidentialitateDate;
