import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
const LinksMenu = () => {
    return (
        <Fragment>
            <NavLink to='/'>Caracteristici</NavLink>
            <NavLink to='/'>Noutati</NavLink>
            <NavLink to='/'>Aplicatie web</NavLink>
            <NavLink to='/contact'>Contact</NavLink>
        </Fragment>
    );
};

export default LinksMenu;
